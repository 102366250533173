// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.About-Title {
  font-weight: bold;
  color: #ffcd40;
}

.About-des {
  line-height: 2;
  font-size: 18px;
}
.About-btn {
  background-color: #3b3525;
  color: #ffcd40;
  border: 1px solid #ffcd40;
}

.About-btn:hover {
  background-color: #ffcd40;
  color: #3b3525;
  border: 1px solid #3b3525;
}
button.dark-btn.btn {
  width: 170px;
}
@media only screen and (max-width: 600px) {
  .About-img {
    width: 100%;
    padding-top: 30px;
  }
  .about {
    padding: 30px;
    text-align: center;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/About.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;AACA;EACE,yBAAyB;EACzB,cAAc;EACd,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,yBAAyB;AAC3B;AACA;EACE,YAAY;AACd;AACA;EACE;IACE,WAAW;IACX,iBAAiB;EACnB;EACA;IACE,aAAa;IACb,kBAAkB;EACpB;AACF","sourcesContent":[".About-Title {\r\n  font-weight: bold;\r\n  color: #ffcd40;\r\n}\r\n\r\n.About-des {\r\n  line-height: 2;\r\n  font-size: 18px;\r\n}\r\n.About-btn {\r\n  background-color: #3b3525;\r\n  color: #ffcd40;\r\n  border: 1px solid #ffcd40;\r\n}\r\n\r\n.About-btn:hover {\r\n  background-color: #ffcd40;\r\n  color: #3b3525;\r\n  border: 1px solid #3b3525;\r\n}\r\nbutton.dark-btn.btn {\r\n  width: 170px;\r\n}\r\n@media only screen and (max-width: 600px) {\r\n  .About-img {\r\n    width: 100%;\r\n    padding-top: 30px;\r\n  }\r\n  .about {\r\n    padding: 30px;\r\n    text-align: center;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
