import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ClientsData from "../data/Cilents.json";
import "./ClinetsInner.css";
function ClinetsInner() {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  // Group clients by categories
  const groupedClients = ClientsData.reduce((acc, client) => {
    if (!acc[client.Categories]) {
      acc[client.Categories] = [];
    }
    acc[client.Categories].push(client);
    return acc;
  }, {});

  return (
    <div className="container p-5 text-center">
      <div className="row">
        <h1 className="cli-h1">Some Of Our Clients</h1>
        {/* Render carousel for each category */}
        {Object.keys(groupedClients).map((category) => (
          <div key={category}>
            <h2 className="p-5">{category}</h2>
            <Carousel
              responsive={responsive}
              showDots={true}
              autoPlaySpeed={1000}
              infinite={true}
            >
              {groupedClients[category].map((client) => (
                <div key={client.ID} className="p-2 clients-slider">
                  <img
                    src={client.Images}
                    alt={client.Name}
                    style={{ maxWidth: "100%" }}
                  />
                </div>
              ))}
            </Carousel>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ClinetsInner;
