import React from "react";
import Breadcramp from "../components/Breadcramp";
import ClinetsInner from "../components/ClinetsInner";

function ClinentsPage() {
  return (
    <div>
      <>
        <Breadcramp />
        <ClinetsInner />
      </>
    </div>
  );
}

export default ClinentsPage;
