import React from "react";
import "./ContactForm.css";
import FormImg from "../images/form.png";
import { useForm } from "react-hook-form";
import useWeb3Forms from "@web3forms/react";
import { useState, useEffect } from "react";

function ContactForm() {
  const { register, reset, handleSubmit } = useForm();

  const [isSuccess, setIsSuccess] = useState(false);
  const [result, setResult] = useState(null);

  const accessKey = "4161ad2c-9e06-441e-a5b9-e6627ee99c30";
  const { submit: onSubmit } = useWeb3Forms({
    access_key: accessKey,
    settings: {
      from_name: "Al Hamad ",
      subject: "New Contact Message from Al Hamad Website",
      // ... other settings
    },
    onSuccess: (msg, data) => {
      setIsSuccess(true);
      setResult(msg);
      reset();
    },
    onError: (msg, data) => {
      setIsSuccess(false);
      setResult(msg);
    },
  });

  return (
    <div class="contact-form-container">
      <form onSubmit={handleSubmit(onSubmit)} class="contact-form">
        <div class="form-group">
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Name"
            required
            {...register("name", { required: true })}
          />
        </div>
        <div class="form-group">
          <input
            type="tel"
            id="number"
            name="number"
            placeholder="Number"
            required
            {...register("number", { required: true })}
          />
        </div>
        <div class="form-group">
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Email"
            required
            {...register("email", { required: true })}
          />
        </div>
        <div class="form-group">
          <textarea
            id="message"
            name="message"
            rows="4"
            placeholder="Message"
            required
            {...register("message", { required: true })}
          ></textarea>
        </div>
        <button type="submit">Submit Form</button>
      </form>
      <div>{result}</div>

      <div class="image-column">
        <img src={FormImg} alt="form Image" />
      </div>
    </div>
  );
}

export default ContactForm;
