// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer {
  color: #fff !important;
  padding: 3% 0px 0px 0px;
  background-position: center;
  background-size: cover;
}
.container-svg {
  width: 100%;
  padding: 0;
  margin-top: -6%;
}

.footer-logo {
  width: 120px;
}
.Footer-ul-Social {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding-left: 0;
}

.Footer-ul-Social li {
  margin: 20px 20px 0 0;
  color: #f79823;
}
.Footer-icon {
  font-size: 20px;
  padding: 10px;
}

.Footer-icon:hover {
  background-color: #fff;
  border-radius: 50%;
  padding: 15px;
}
.Footer-ul-contact {
  list-style-type: none;
  padding-left: 0;
}
.Footer-ul-contact a {
  text-decoration: none;
}
.Footer-ul-contact li {
  padding-bottom: 7px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  text-decoration: none;
}
.Page-icon {
  color: #ffcd40;
  margin-right: 7px;
}

li:hover {
  color: #f79823;
}
a:link {
  text-decoration: none;
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,uBAAuB;EACvB,2BAA2B;EAC3B,sBAAsB;AACxB;AACA;EACE,WAAW;EACX,UAAU;EACV,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;AACA;EACE,aAAa;EACb,eAAe;EACf,qBAAqB;EACrB,eAAe;AACjB;;AAEA;EACE,qBAAqB;EACrB,cAAc;AAChB;AACA;EACE,eAAe;EACf,aAAa;AACf;;AAEA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,aAAa;AACf;AACA;EACE,qBAAqB;EACrB,eAAe;AACjB;AACA;EACE,qBAAqB;AACvB;AACA;EACE,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,WAAW;EACX,qBAAqB;AACvB;AACA;EACE,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,cAAc;AAChB;AACA;EACE,qBAAqB;EACrB,WAAW;AACb","sourcesContent":[".Footer {\r\n  color: #fff !important;\r\n  padding: 3% 0px 0px 0px;\r\n  background-position: center;\r\n  background-size: cover;\r\n}\r\n.container-svg {\r\n  width: 100%;\r\n  padding: 0;\r\n  margin-top: -6%;\r\n}\r\n\r\n.footer-logo {\r\n  width: 120px;\r\n}\r\n.Footer-ul-Social {\r\n  display: flex;\r\n  flex-wrap: wrap;\r\n  list-style-type: none;\r\n  padding-left: 0;\r\n}\r\n\r\n.Footer-ul-Social li {\r\n  margin: 20px 20px 0 0;\r\n  color: #f79823;\r\n}\r\n.Footer-icon {\r\n  font-size: 20px;\r\n  padding: 10px;\r\n}\r\n\r\n.Footer-icon:hover {\r\n  background-color: #fff;\r\n  border-radius: 50%;\r\n  padding: 15px;\r\n}\r\n.Footer-ul-contact {\r\n  list-style-type: none;\r\n  padding-left: 0;\r\n}\r\n.Footer-ul-contact a {\r\n  text-decoration: none;\r\n}\r\n.Footer-ul-contact li {\r\n  padding-bottom: 7px;\r\n  font-size: 18px;\r\n  font-weight: 600;\r\n  color: #fff;\r\n  text-decoration: none;\r\n}\r\n.Page-icon {\r\n  color: #ffcd40;\r\n  margin-right: 7px;\r\n}\r\n\r\nli:hover {\r\n  color: #f79823;\r\n}\r\na:link {\r\n  text-decoration: none;\r\n  color: #fff;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
