import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import slide41 from "../images/slider41.jpg";
import slide43 from "../images/slider43.jpg";
import slide44 from "../images/slider44.jpg";
import logo from "../images/logo.png";
import "./Slider.css";
import { Link } from "react-router-dom";

function Slider() {
  return (
    <>
      <Carousel
        autoPlay={true}
        showThumbs={false}
        infiniteLoop={true}
        stopOnHover={true}
      >
        <div>
          <img src={slide41} alt="slide41" />
          <p className="slider-logo child">
            <img className="logo-img" src={logo} alt="logo" />
          </p>
          <div>
            <Link to="/Products">
              <button className="slider-btn">Shop Now</button>
            </Link>
          </div>
        </div>
        <div>
          <img src={slide43} alt="slide43" />
          <p className="slider-logo child">
            <img className="logo-img" src={logo} />
          </p>
          <div>
            <Link to="/Products">
              <button className="slider-btn">Shop Now</button>
            </Link>
          </div>
        </div>
        <div>
          <img src={slide44} alt="slide44" />
          <p className="slider-logo child">
            <img className="logo-img" src={logo} />
          </p>
          <div>
            <Link to="/Products">
              <button className="slider-btn">Shop Now</button>
            </Link>
          </div>
        </div>
      </Carousel>
    </>
  );
}

export default Slider;
