// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Mission-container {
  padding: 50px 0px 150px;
  background-attachment: fixed;
}

.Mission-icon {
  width: 3em;
  height: 3em;
  position: relative;
  display: block;
  color: #ffcd40;
  margin-bottom: 10px;
}

.icon-box {
  justify-content: center;
  display: flex;
}
.card {
  border: 0;
  background-color: transparent;
}
@media only screen and (max-width: 600px) {
  .Mission-container {
    padding: 50px 30px 150px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Mission.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,4BAA4B;AAC9B;;AAEA;EACE,UAAU;EACV,WAAW;EACX,kBAAkB;EAClB,cAAc;EACd,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,aAAa;AACf;AACA;EACE,SAAS;EACT,6BAA6B;AAC/B;AACA;EACE;IACE,wBAAwB;EAC1B;AACF","sourcesContent":[".Mission-container {\r\n  padding: 50px 0px 150px;\r\n  background-attachment: fixed;\r\n}\r\n\r\n.Mission-icon {\r\n  width: 3em;\r\n  height: 3em;\r\n  position: relative;\r\n  display: block;\r\n  color: #ffcd40;\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.icon-box {\r\n  justify-content: center;\r\n  display: flex;\r\n}\r\n.card {\r\n  border: 0;\r\n  background-color: transparent;\r\n}\r\n@media only screen and (max-width: 600px) {\r\n  .Mission-container {\r\n    padding: 50px 30px 150px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
