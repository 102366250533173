import React from "react";
import "./ContactBox.css";
import {
  faLocationDot,
  faEnvelope,
  faClock,
  faTty,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ContactBox() {
  return (
    <div className="container">
      <div className="row p-5">
        <div className="col-md-3 card text-center">
          <div className="icon-box">
            <FontAwesomeIcon className="Mission-icon" icon={faLocationDot} />
          </div>
          <h5 className="Mission-h">
            Al Kharqaneyah, El Qanater El Khayreya, Al Qalyubia ,Egypt
          </h5>
        </div>
        <div className="col-md-3 card text-center">
          <div className="icon-box">
            <FontAwesomeIcon className="Mission-icon" icon={faEnvelope} />
          </div>
          <h5 className="Mission-h">
            <a href="mailto:info@alhamadbakery.com" style={{ color: "black " }}>
              Mail: info@alhamadbakery.com
            </a>
          </h5>
        </div>
        <div className="col-md-3 card text-center">
          <div className="icon-box">
            <FontAwesomeIcon className="Mission-icon" icon={faClock} />
          </div>
          <h5 className="Mission-h">
            Week Days: 10:00 am– 22:00 pm Sunday: Close
          </h5>
        </div>
        <div className="col-md-3 card text-center">
          <div className="icon-box">
            <FontAwesomeIcon className="Mission-icon" icon={faTty} />
          </div>
          <ul className="Contact-ul-contact">
            <a href="tel:+201020340444" style={{ color: "black " }}>
              <li>01020340444</li>
            </a>

            <a href="tel:+201281098975" style={{ color: "black " }}>
              <li>01281098975</li>
            </a>

            <a href="tel:+201222547650" style={{ color: "black " }}>
              <li>01222547650</li>
            </a>

            <a href="tel:+201020124333" style={{ color: "black " }}>
              <li>01020124333</li>
            </a>

            <a href="tel:+201004502385" style={{ color: "black " }}>
              <li>01004502385</li>
            </a>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ContactBox;
