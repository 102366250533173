import React from "react";
import Slider from "../components/Slider";
import About from "../components/About";
import Mission from "../components/Mission";
import Process from "../components/Process";
import Products from "../components/products";
import Choose from "../components/Choose";
import Clinets from "../components/Clinets";
function Home() {
  return (
    <div>
      <>
        <Slider />
        <About />
        <Mission />
        <Process />
        <Products />
        <Choose />
        <Clinets />
      </>
    </div>
  );
}

export default Home;
