// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body {
  overflow-x: hidden;
}

.white-btn {
  background-color: rgb(255, 205, 64) !important;
  font-family: "Cera Pro Bold";
  text-transform: uppercase;
  cursor: pointer;
  line-height: 17px;
  padding: 14px 33px;
  color: #fff;
  border-radius: 30px;
}
.white-btn:hover {
  background-color: #3b3525 !important;
  border: 1px solid #ffcd40;
}

.dark-btn {
  background-color: #3b3525 !important;
  font-family: "Cera Pro Bold";
  text-transform: uppercase;
  cursor: pointer;
  line-height: 17px;
  padding: 14px 33px;
  color: #fff;
  border-radius: 30px;
  border: 2px solid #ffcd40 !important;
}
.dark-btn:hover {
  background-color: #ffcd40 !important;
  border: 2px solid #3b3525 !important;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;;EAEE,kBAAkB;AACpB;;AAEA;EACE,8CAA8C;EAC9C,4BAA4B;EAC5B,yBAAyB;EACzB,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,WAAW;EACX,mBAAmB;AACrB;AACA;EACE,oCAAoC;EACpC,yBAAyB;AAC3B;;AAEA;EACE,oCAAoC;EACpC,4BAA4B;EAC5B,yBAAyB;EACzB,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,WAAW;EACX,mBAAmB;EACnB,oCAAoC;AACtC;AACA;EACE,oCAAoC;EACpC,oCAAoC;AACtC","sourcesContent":["html,\r\nbody {\r\n  overflow-x: hidden;\r\n}\r\n\r\n.white-btn {\r\n  background-color: rgb(255, 205, 64) !important;\r\n  font-family: \"Cera Pro Bold\";\r\n  text-transform: uppercase;\r\n  cursor: pointer;\r\n  line-height: 17px;\r\n  padding: 14px 33px;\r\n  color: #fff;\r\n  border-radius: 30px;\r\n}\r\n.white-btn:hover {\r\n  background-color: #3b3525 !important;\r\n  border: 1px solid #ffcd40;\r\n}\r\n\r\n.dark-btn {\r\n  background-color: #3b3525 !important;\r\n  font-family: \"Cera Pro Bold\";\r\n  text-transform: uppercase;\r\n  cursor: pointer;\r\n  line-height: 17px;\r\n  padding: 14px 33px;\r\n  color: #fff;\r\n  border-radius: 30px;\r\n  border: 2px solid #ffcd40 !important;\r\n}\r\n.dark-btn:hover {\r\n  background-color: #ffcd40 !important;\r\n  border: 2px solid #3b3525 !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
