import React from "react";
import "./Choose.css";
import ChooseBG from "../images/Choose-bg.webp";
import icon10 from "../images/icon10.webp";
import icon11 from "../images/icon11.webp";
import icon12 from "../images/icon12.webp";
import icon9 from "../images/icon9.webp";
import Logo from "../images/logo.png";

function Choose() {
  return (
    <div>
      <div
        className="container-fluid text-center Choose"
        style={{ backgroundImage: `url(${ChooseBG})` }}
      >
        <div className="container">
          <h2> Why Choose Us</h2>
          <div className="row pt-5">
            {/* // left col */}
            <div className="col-md-4">
              <div className="Choose-box">
                <div className="img-box">
                  <img src={icon9} className="hover-effect" />
                </div>
                <div className="text-box">
                  <h4>Eat Healthier</h4>
                  <p>Modi tempora incidunt ut labore dolore magnam aliquam</p>
                </div>
              </div>
              <div className="Choose-box">
                <div className="img-box">
                  <img src={icon10} className="hover-effect" />
                </div>
                <div className="text-box">
                  <h4>We Have Brands</h4>
                  <p>MModi tempora incidunt ut labore dolore magnam aliquam</p>
                </div>
              </div>
            </div>

            {/* middile col  */}
            <div className="col-md-4">
              <img className="choose-logo" src={Logo} alt="Logo" />
            </div>
            {/* Ro=ight Col */}
            <div className="col-md-4">
              <div className="Choose-box left">
                <div className="img-box">
                  <img src={icon11} className="hover-effect" />
                </div>
                <div className="text-box">
                  <h4>Eat Healthier</h4>
                  <p>Modi tempora incidunt ut labore dolore magnam aliquam</p>
                </div>
              </div>
              <div className="Choose-box left">
                <div className="img-box">
                  <img src={icon12} className="hover-effect" />
                </div>
                <div className="text-box">
                  <h4>We Have Brands</h4>
                  <p>MModi tempora incidunt ut labore dolore magnam aliquam</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Choose;
