// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#backToTop {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px;
  border-radius: 37px;
  cursor: pointer;
  transition: opacity 0.5s;
  width: 60px;
  z-index: 99;
}

#whatsappButton {
  display: block;
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px;
  border-radius: 37px;
  cursor: pointer;
  transition: opacity 0.5s;
  width: 60px;
  z-index: 99;
}

#whatsappButton:hover,
#backToTop:hover {
  opacity: 0.8;
}
`, "",{"version":3,"sources":["webpack://./src/components/Backtotop.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,YAAY;EACZ,WAAW;EACX,oCAAoC;EACpC,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,wBAAwB;EACxB,WAAW;EACX,WAAW;AACb;;AAEA;EACE,cAAc;EACd,eAAe;EACf,YAAY;EACZ,UAAU;EACV,oCAAoC;EACpC,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,wBAAwB;EACxB,WAAW;EACX,WAAW;AACb;;AAEA;;EAEE,YAAY;AACd","sourcesContent":["#backToTop {\r\n  display: none;\r\n  position: fixed;\r\n  bottom: 20px;\r\n  right: 20px;\r\n  background-color: rgba(0, 0, 0, 0.7);\r\n  color: white;\r\n  padding: 10px;\r\n  border-radius: 37px;\r\n  cursor: pointer;\r\n  transition: opacity 0.5s;\r\n  width: 60px;\r\n  z-index: 99;\r\n}\r\n\r\n#whatsappButton {\r\n  display: block;\r\n  position: fixed;\r\n  bottom: 20px;\r\n  left: 20px;\r\n  background-color: rgba(0, 0, 0, 0.7);\r\n  color: white;\r\n  padding: 10px;\r\n  border-radius: 37px;\r\n  cursor: pointer;\r\n  transition: opacity 0.5s;\r\n  width: 60px;\r\n  z-index: 99;\r\n}\r\n\r\n#whatsappButton:hover,\r\n#backToTop:hover {\r\n  opacity: 0.8;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
