import React from "react";
import "./Mission.css";
import {
  faBullseye,
  faEyeLowVision,
  faHandshake,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Missionbg from "../images/mission.jpg";

function Mission() {
  return (
    <div>
      <>
        <div
          className="container-fliud Mission-container"
          style={{ backgroundImage: `url(${Missionbg})` }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-4 card text-center">
                <div className="icon-box">
                  <FontAwesomeIcon className="Mission-icon" icon={faBullseye} />
                </div>
                <h3 className="Mission-h">Our Mission</h3>
                <p className="Mission-des">
                  AL Hamd seeks to be the best bakery in Egypt by offering the
                  best quality on the market smartly packaged, hygienic and
                  tasty products in a good packaging.
                </p>
              </div>
              <div className="col-md-4 card text-center">
                <div className="icon-box">
                  <FontAwesomeIcon
                    className="Mission-icon"
                    icon={faEyeLowVision}
                  />
                </div>
                <h3 className="Mission-h">Our Vision</h3>
                <p className="Mission-des">
                  We strive for our products to be widespread in the local and
                  international market. Continually innovate in the baking
                  industry With product development continuously.
                </p>
              </div>
              <div className="col-md-4 card text-center">
                <div className="icon-box">
                  <FontAwesomeIcon
                    className="Mission-icon"
                    icon={faHandshake}
                  />
                </div>
                <h3 className="Mission-h">Values</h3>
                <p className="Mission-des">
                  In order to provide our customers with consistently high
                  quality products we at Al-Hamad maintain a set of values
                  designed to guarantee quality . We believe in: Honesty Quality
                  Team Spirit Leadership and, most importantly
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default Mission;
