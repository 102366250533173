// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.divider {
  padding: 30px;
  margin-top: -100px;
  background-color: #fff;
  position: absolute;
  left: 10%;
  right: auto;
}
.divid-img {
  width: 50px;
  padding-bottom: 10px;
}
.card {
  justify-content: center;
  display: flex;
}
.border1 {
  border-right: 1px solid #ededed;
}

.Delivery-Process {
  padding: 180px 150px 50px 150px;
  background-attachment: fixed;
  background-size: cover;
}

.prosses-titlte {
  color: #f79823;
  padding-top: 15px;
  font-size: 15px;
}
.prosses-des {
  color: #fff;
  font-size: 20px;
}
.Process-img {
  padding-top: 40px;
}
.next-con {
  position: relative;
}
.next-img {
  position: absolute;
}
img.next-img {
  width: 260px;
  padding-left: 50px;
}
@media only screen and (max-width: 600px) {
  .divider {
    width: 80%;
  }
  .divid-img {
    width: 30px;
    padding-bottom: 10px;
  }
  .card h4 {
    font-size: 15px;
  }
  .card p {
    font-size: 12px;
  }

  .Delivery-Process {
    padding: 180px 50px 50px 50px;
  }
  img.next-img {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Process.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,sBAAsB;EACtB,kBAAkB;EAClB,SAAS;EACT,WAAW;AACb;AACA;EACE,WAAW;EACX,oBAAoB;AACtB;AACA;EACE,uBAAuB;EACvB,aAAa;AACf;AACA;EACE,+BAA+B;AACjC;;AAEA;EACE,+BAA+B;EAC/B,4BAA4B;EAC5B,sBAAsB;AACxB;;AAEA;EACE,cAAc;EACd,iBAAiB;EACjB,eAAe;AACjB;AACA;EACE,WAAW;EACX,eAAe;AACjB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,kBAAkB;AACpB;AACA;EACE;IACE,UAAU;EACZ;EACA;IACE,WAAW;IACX,oBAAoB;EACtB;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;;EAEA;IACE,6BAA6B;EAC/B;EACA;IACE,aAAa;EACf;AACF","sourcesContent":[".divider {\r\n  padding: 30px;\r\n  margin-top: -100px;\r\n  background-color: #fff;\r\n  position: absolute;\r\n  left: 10%;\r\n  right: auto;\r\n}\r\n.divid-img {\r\n  width: 50px;\r\n  padding-bottom: 10px;\r\n}\r\n.card {\r\n  justify-content: center;\r\n  display: flex;\r\n}\r\n.border1 {\r\n  border-right: 1px solid #ededed;\r\n}\r\n\r\n.Delivery-Process {\r\n  padding: 180px 150px 50px 150px;\r\n  background-attachment: fixed;\r\n  background-size: cover;\r\n}\r\n\r\n.prosses-titlte {\r\n  color: #f79823;\r\n  padding-top: 15px;\r\n  font-size: 15px;\r\n}\r\n.prosses-des {\r\n  color: #fff;\r\n  font-size: 20px;\r\n}\r\n.Process-img {\r\n  padding-top: 40px;\r\n}\r\n.next-con {\r\n  position: relative;\r\n}\r\n.next-img {\r\n  position: absolute;\r\n}\r\nimg.next-img {\r\n  width: 260px;\r\n  padding-left: 50px;\r\n}\r\n@media only screen and (max-width: 600px) {\r\n  .divider {\r\n    width: 80%;\r\n  }\r\n  .divid-img {\r\n    width: 30px;\r\n    padding-bottom: 10px;\r\n  }\r\n  .card h4 {\r\n    font-size: 15px;\r\n  }\r\n  .card p {\r\n    font-size: 12px;\r\n  }\r\n\r\n  .Delivery-Process {\r\n    padding: 180px 50px 50px 50px;\r\n  }\r\n  img.next-img {\r\n    display: none;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
