import React from "react";
import About from "../components/About";
import Breadcramp from "../components/Breadcramp";
import Clinets from "../components/Clinets";
function AboutPage() {
  return (
    <div>
      <>
        <Breadcramp />
        <About />
        <Clinets />
      </>
    </div>
  );
}

export default AboutPage;
