import "./Breadcramp.css";
import React from "react";
import BreadcrampBg from "../images/breadcrambBG.jpg";

function Breadcramp() {
  // Get the current page name from the URL
  const getPageName = () => {
    // Split the pathname by '/' and get the last segment (page name)
    const pathSegments = window.location.pathname.split("/");
    return pathSegments[pathSegments.length - 1];
  };

  // Render the component
  return (
    <div className="container-fulid ">
      <div
        className="row Breadcramp text-center"
        style={{ backgroundImage: `url(${BreadcrampBg})` }}
      >
        <h1 className="pt-5 mt-5">{getPageName()}</h1>
      </div>
    </div>
  );
}

export default Breadcramp;
