import React from "react";
import "./Process.css";
import shipping from "../images/shipping.jpg";
import time from "../images/time.jpg";
import payment from "../images/payment.jpg";
import step01 from "../images/step1.png";
import step02 from "../images/step2.png";
import step03 from "../images/step3.png";
import step04 from "../images/step4.png";

import ProcessBg from "../images/Delivery-Process-bg.jpg";
import next from "../images/line-step.png";
function Process() {
  return (
    <div>
      <div className="container divider">
        <div className="row">
          <div className="col-4  card text-center border1">
            <div>
              <img className="divid-img" src={shipping} alt="shipping" />
            </div>
            <h4>SHIPPING </h4>
            <p>For Affordable Price</p>
          </div>
          <div className="col-4 card text-center border1">
            <div>
              <img className="divid-img" src={time} alt="time" />
            </div>

            <h4>Delivery On Time </h4>
            <p>If good have prolems</p>
          </div>
          <div className="col-4 card text-center">
            <div>
              <img className="divid-img" src={payment} alt="payment" />
            </div>
            <h4>Payment </h4>
            <p>All Type Of payment</p>
          </div>
        </div>
      </div>

      {/* Delivery-Process Section  */}

      <div
        className="container-fliud Delivery-Process"
        style={{ backgroundImage: `url(${ProcessBg})` }}
      >
        <div className="row">
          <h2 className="text-center text-white pb-5">Delivery Process</h2>
          {/* step1 */}
          <div className="col-md-3 text-center">
            <div className="next-con">
              <img className="next-img" src={next} alt="next" />
            </div>
            <div>
              <img className="Process-img" src={step01} alt="step01" />
            </div>
            <h3 className="prosses-titlte">STEP 01</h3>
            <p className="prosses-des">Choose Your Products</p>
          </div>
          {/* STEP 02 */}

          <div className="col-md-3 text-center">
            <div className="next-con">
              <img className="next-img" src={next} alt="next" />
            </div>
            <div>
              <img className="Process-img" src={step02} alt="step01" />
            </div>
            <h3 className="prosses-titlte">STEP 02</h3>
            <p className="prosses-des">Connect Nearest Farm</p>
          </div>
          {/* STEP 03 */}

          <div className="col-md-3 text-center">
            <div className="next-con">
              <img className="next-img" src={next} alt="next" />
            </div>
            <div>
              <img className="Process-img" src={step03} alt="step01" />
            </div>
            <h3 className="prosses-titlte">STEP 03</h3>
            <p className="prosses-des">Share Your Location</p>
          </div>
          {/* STEP 04 */}

          <div className="col-md-3 text-center">
            <div>
              <img className="Process-img" src={step04} alt="step01" />
            </div>
            <h3 className="prosses-titlte">STEP 04</h3>
            <p className="prosses-des">Get Delivered Fast</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Process;
