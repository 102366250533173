import React from "react";
import Breadcramp from "../components/Breadcramp";
import InnerProducts from "../components/InnerProducts";
function ProductsPage() {
  return (
    <div>
      <>
        <Breadcramp />
        <InnerProducts />
      </>
    </div>
  );
}

export default ProductsPage;
