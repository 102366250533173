import React from "react";
import Breadcramp from "../components/Breadcramp";
import Map from "../components/Map";
import ContactBox from "../components/ContactBox";
import ContactForm from "../components/ContactForm";
function ContactUS() {
  return (
    <div>
      <>
        <Breadcramp />
        <Map />
        <ContactBox />
        <ContactForm />
      </>
    </div>
  );
}

export default ContactUS;
