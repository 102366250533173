// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cli-h1 {
  color: black;
}

@media only screen and (max-width: 600px) {
  .cli-h1 {
    font-size: 25px;
    font-weight: bold;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ClinetsInner.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE;IACE,eAAe;IACf,iBAAiB;EACnB;AACF","sourcesContent":[".cli-h1 {\r\n  color: black;\r\n}\r\n\r\n@media only screen and (max-width: 600px) {\r\n  .cli-h1 {\r\n    font-size: 25px;\r\n    font-weight: bold;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
