// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Contact-ul-contact li {
  margin: 10px 10px 0 0;
  color: #000;
  list-style-type: none;
  text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/ContactBox.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,WAAW;EACX,qBAAqB;EACrB,qBAAqB;AACvB","sourcesContent":[".Contact-ul-contact li {\r\n  margin: 10px 10px 0 0;\r\n  color: #000;\r\n  list-style-type: none;\r\n  text-decoration: none;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
