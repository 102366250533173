// App.js
import "./App.css";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import About from "./pages/About";
import Products from "./pages/Products";
import Clients from "./pages/Clinents";
import Contact from "./pages/Contact";
import Backtotop from "./components/Backtotop";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Navbar />
        <Routes>
          <Route index element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Products" element={<Products />} />
          <Route path="/Clients" element={<Clients />} />
          <Route path="/Contact" element={<Contact />} />
        </Routes>
        <Footer />
        <Backtotop />
      </div>
    </BrowserRouter>
  );
}

export default App;
