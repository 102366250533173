// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Choose {
  padding: 100px;
  background-position: center;
  background-attachment: fixed;
  color: #fff;
}
.Choose-box {
  display: flex;
  text-align: right;
  direction: rtl;
}

.left {
  display: flex;
  text-align: left;
  direction: ltr;
}

.Singel-box {
  display: inline-grid;
  width: 75%;
  float: left;
}
.img-box {
  display: inline;
  width: 25%;
}

.img-box {
  display: inline-block;
  position: relative;
}

.hover-effect {
  transition: transform 0.3s ease;
}

.img-box:hover .hover-effect {
  transform: translateY(-5px); /* Move up */
}

.img-box:hover .hover-effect:hover {
  transform: translateY(0); /* Move down */
}

.choose-logo {
  max-width: 130px;
}

@media only screen and (max-width: 600px) {
  .Choose {
    padding: 50px 30px 50px 30px;
  }
  .Choose-box {
    display: flex;
    text-align: left;
    direction: ltr;
  }
  .img-box {
    padding-right: 20px;
  }
  .choose-logo {
    padding: 30px 0 30px 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Choose.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,2BAA2B;EAC3B,4BAA4B;EAC5B,WAAW;AACb;AACA;EACE,aAAa;EACb,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,oBAAoB;EACpB,UAAU;EACV,WAAW;AACb;AACA;EACE,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,2BAA2B,EAAE,YAAY;AAC3C;;AAEA;EACE,wBAAwB,EAAE,cAAc;AAC1C;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;IACE,4BAA4B;EAC9B;EACA;IACE,aAAa;IACb,gBAAgB;IAChB,cAAc;EAChB;EACA;IACE,mBAAmB;EACrB;EACA;IACE,sBAAsB;EACxB;AACF","sourcesContent":[".Choose {\r\n  padding: 100px;\r\n  background-position: center;\r\n  background-attachment: fixed;\r\n  color: #fff;\r\n}\r\n.Choose-box {\r\n  display: flex;\r\n  text-align: right;\r\n  direction: rtl;\r\n}\r\n\r\n.left {\r\n  display: flex;\r\n  text-align: left;\r\n  direction: ltr;\r\n}\r\n\r\n.Singel-box {\r\n  display: inline-grid;\r\n  width: 75%;\r\n  float: left;\r\n}\r\n.img-box {\r\n  display: inline;\r\n  width: 25%;\r\n}\r\n\r\n.img-box {\r\n  display: inline-block;\r\n  position: relative;\r\n}\r\n\r\n.hover-effect {\r\n  transition: transform 0.3s ease;\r\n}\r\n\r\n.img-box:hover .hover-effect {\r\n  transform: translateY(-5px); /* Move up */\r\n}\r\n\r\n.img-box:hover .hover-effect:hover {\r\n  transform: translateY(0); /* Move down */\r\n}\r\n\r\n.choose-logo {\r\n  max-width: 130px;\r\n}\r\n\r\n@media only screen and (max-width: 600px) {\r\n  .Choose {\r\n    padding: 50px 30px 50px 30px;\r\n  }\r\n  .Choose-box {\r\n    display: flex;\r\n    text-align: left;\r\n    direction: ltr;\r\n  }\r\n  .img-box {\r\n    padding-right: 20px;\r\n  }\r\n  .choose-logo {\r\n    padding: 30px 0 30px 0;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
