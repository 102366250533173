import React from "react";
import "./prodects.css";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import data from "../data/ProductsData.json";
function InnerProducts() {
  return (
    <div>
      <div className="container products text-center">
        <div className="row products-row">
          {data.map((data, id) => (
            <div key={id} className="col-md-3 col-sm-6 product-card">
              <div>
                <img
                  className="products-img"
                  src={data.Images}
                  alt="products-img"
                />
              </div>

              <h4 className="product-cat">{data.Categories}</h4>
              <h2 className="product-name">{data.Name}</h2>
              <a className="product-icon">
                <FontAwesomeIcon icon={faEye} />
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default InnerProducts;
