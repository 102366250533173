import React from "react";

function map() {
  return (
    <div className="container">
      <iframe
        title="google map"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13796.845269385718!2d31.172292!3d30.173957000000005!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14586bd4d7d79ee1%3A0xed5ee3a686314ad8!2sAl%20Kharqaniyyah%2C%20Al%20Kharqaneyah%2C%20El%20Qanater%20El%20Khayreya%2C%20Al-Qalyubia%20Governorate%2C%20Egypt!5e0!3m2!1sen!2sus!4v1719853107527!5m2!1sen!2sus"
        width="100%"
        height="500"
        style={{ border: 0 }}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
}

export default map;
