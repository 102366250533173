import React, { useState, useEffect } from "react";
import Logo from "../images/logo.png";
import "./Navbar.css";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

function Navbar() {
  const [color, setColor] = useState(false);
  const [marginTop, setMarginTop] = useState("25px");
  const [isOpen, setIsOpen] = useState(false); // حالة لفتح القائمة

  const changeColor = () => {
    if (window.scrollY >= 90) {
      setColor(true);
      setMarginTop("0px");
    } else {
      setColor(false);
      setMarginTop("25px");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeColor);
    return () => {
      window.removeEventListener("scroll", changeColor);
    };
  }, []);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = () => {
    setIsOpen(false); // اغلاق القائمة عند الضغط على رابط
  };

  return (
    <div className="Navbar-main">
      <div className="container top-bar navbar navbar-expand-lg">
        <div className="col-md-6 col-sm-12">
          <ul className="top-bar-ul">
            <li>
              <FontAwesomeIcon className="primery-icon" icon={faPhone} />
              01020340444
            </li>
            <li>
              <FontAwesomeIcon className="primery-icon" icon={faEnvelope} />
              info@Alhamadbakery.com
            </li>
          </ul>
        </div>
        <div className="col-md-6 m-none">
          <ul className="top-bar-ul justify-content-end">
            <li>
              <FontAwesomeIcon className="primery-icon" icon={faFacebook} />
            </li>
            <li>
              <FontAwesomeIcon className="primery-icon" icon={faTwitter} />
            </li>
            <li>
              <FontAwesomeIcon className="primery-icon" icon={faInstagram} />
            </li>
          </ul>
        </div>
      </div>

      <nav
        className={
          color
            ? "navbar fixed-top navbar-expand-lg header-bg"
            : "navbar fixed-top navbar-expand-lg"
        }
      >
        <div className="container snd-nav" style={{ marginTop }}>
          <Link
            className="nav-link active navbar-brand"
            to="/"
            onClick={handleLinkClick}
          >
            <img src={Logo} alt="Al-hamed-logo" className="logo" />
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            onClick={handleToggle}
            aria-controls="navbarNav"
            aria-expanded={isOpen}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`collapse navbar-collapse ${isOpen ? "show" : ""}`}
            id="navbarNav"
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link
                  className="nav-link active"
                  to="/"
                  onClick={handleLinkClick}
                >
                  HOME
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/About"
                  onClick={handleLinkClick}
                >
                  ABOUT
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/Products"
                  onClick={handleLinkClick}
                >
                  PRODUCTS
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/Clients"
                  onClick={handleLinkClick}
                >
                  CLIENTS
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/Contact"
                  onClick={handleLinkClick}
                >
                  CONTACT
                </Link>
              </li>
              <li>
                <a href="tel:+201020340444">
                  <button className="btn white-btn nav-btn">Call Us</button>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
